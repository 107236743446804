import React from 'react'
import Footer from '../../components/Footer'
import { CenteredHero } from '../../components/Hero/CenteredHero'
import { PricingCard } from '../../components/PricingCard/PricingCard'
import { QueryGraphql } from '../../utils/graphQlQuery'
import './pricing.scss'
import { PricingData } from '../../utils/GraphQL/pricingQueryData'

const Pricing = () => {
  let data = QueryGraphql()
  const pricingData = PricingData()
  const {
    allContentfulPricing: { nodes: pricingNodes },
  } = pricingData

  const {
    allContentfulHero: { nodes },
  } = data

  // const mainData = desc.find((subject) => subject.title === 'Akta Pricing')
  const mainData = nodes
    .filter((val) => val.header === 'Akta Pricing')
    .filter((val) => {
      const { desc: mainDesc } = val
      return mainDesc
    })

  const results = mainData[0]

  const { header, mainDescription, title: info, backgroundColor } = results
  const pageDescription = mainDescription.join('')

  return (
    <div className="pricing-container">
      <div>
        <CenteredHero
          pageDescription={pageDescription}
          background={backgroundColor}
          pageInfo={info}
        />
      </div>

      <div className="pricing-fluid-container">
        <div className="col">
          <div className="ht-100 text-center">
            <h3>Choose a plan that works for you.</h3>
          </div>
          <div className="ht-100 mx-auto w-50">
            <p className="text-center mx-auto">
              Start today, with our free or premium plans. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Volutpat id enim ac egestas
              nibh ac tellus. Vestibulum tempor non arcu, donec scelerisque
              elementum molestie volutpat elit.
            </p>
          </div>
          <div className="row sp-ev ht-100 mt-5 mb-10 mobile-col">
            {pricingNodes &&
              pricingNodes.map((val, ind) => (
                <PricingCard
                  key={ind}
                  enablePinkHeader={val?.enablePinkHeader}
                  price={val?.price}
                  buttonType={val?.buttonType}
                  title={val?.title}
                  buttonText={val?.buttonText}
                  backgroundType={val?.backgroundType}
                  darkDivider={val?.darkDivider}
                  options={val?.options}
                />
              ))}

            {/* <PricingCard
              enablePinkHeader={true}
              price={`Free`}
              buttonType={`primary`}
              title={`Basic Plan`}
              buttonText={`Join For Free`}
            />
            <PricingCard backgroundType={`bg-primary-main `} />
            <PricingCard
              enablePinkHeader={true}
              buttonText={`Get Premium`}
              price={`$200`}
              buttonType={`primary`}
              title={`Enterprise Plan`}
            /> */}
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Pricing
