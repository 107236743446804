import { Link } from 'gatsby'
import React from 'react'
import CTA from '../CTA'
import './pricingcard.scss'

export const PricingCard = ({
  title,
  price,
  description,
  backgroundType,
  enablePinkHeader,
  buttonType,
  buttonText,
  darkDivider,
  options,
}) => {
  return (
    <div
      className={`main-card-container ${backgroundType ?? `bg-primary`
        } bd-rd-4 p-5`}
    >
      <div className={`col ml-3 p-2 ${enablePinkHeader ? `text-pink` : ``}`}>
        <h5>{title ?? 'STANDARD PLAN'}</h5>
      </div>
      <div className="col ml-3 p-2 mb-5">
        <div className="word-nowrap">
          <span className="ft-40 ft-bold ">{price ?? '$100'}</span>
          <span className="ft-20 ">{price === `Free` ? '' : '/Year'}</span>
        </div>
        <span className="ft-light">
          {description ??
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat id enim ac egestas nibh ac tellus.`}
        </span>

        <div
          className={` ${darkDivider ? 'straight-line-dark' : 'straight-line'
            }   mt-5`}
        ></div>
      </div>

      <div className="col  p-2">
        <ol className="price-inline-text">
          {options.map((val, ind) => {
            return <li key={ind}>{val}</li>
          })}
        </ol>
      </div>

      <div className="col mt-5">
        <Link to="#0" className="w-100 ml-10 mt-5 align-center mobile-button">
          <CTA width="80%" size="1em" type={buttonType ?? `secondary-v1`}>
            {buttonText ?? `Get Premium`}
          </CTA>
        </Link>
      </div>
    </div>
  )
}
