import { useStaticQuery, graphql } from 'gatsby'

export const PricingData = () => {
  const queryData = useStaticQuery(graphql`
    query PricingTypes {
      allContentfulPricing(sort: { order: ASC, fields: createdAt }) {
        nodes {
          title
          description
          backgroundType
          enablePinkHeader
          enablePinkHeader
          price
          options
          buttonText
          buttonType
        }
      }
    }
  `)

  return queryData
}
